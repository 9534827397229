import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PlaylistsStereoSpacesService, AuthenticationService } from 'sostereo-services';
import { CommonService } from '../../../../shared/services/common.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { TrackingService } from 'src/app/angular/shared/services/tracking.service';
import { PlayerService } from '../../../../shared/services/player.service';
import { NgClass, NgIf } from '@angular/common';
import { BtnComponent } from 'src/app/components/btn/btn.component';

@Component({
  selector: 'app-new-playlist-modal',
  templateUrl: './new-playlist-modal.component.html',
  styleUrls: ['./new-playlist-modal.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgClass, NgIf, BtnComponent],
})
export class NewPlaylistModalComponent {
  @ViewChild('newPlaylistModal', { static: true }) modalTemplate: TemplateRef<any>;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() success = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() error = new EventEmitter();
  public modalRef: BsModalRef;
  playlistForm: FormGroup;
  private playlistsCount: BehaviorSubject<number> = this.commonService.playlistsCount;
  public displayPrivacy = false;
  public loading = false;

  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private authenticationService: AuthenticationService,
    private playlistsStereoSpacesService: PlaylistsStereoSpacesService,
    private trackingService: TrackingService,
    public playerService: PlayerService,
  ) {
    const user = this.authenticationService.getCurrentUser();
    this.displayPrivacy = user?.hasOwnProperty('groups') && user?.groups?.length > 0;
    this.createForm();
  }

  private createForm() {
    this.playlistForm = this.formBuilder.group({
      name: new FormControl(null, [Validators.required, this.noWhitespaceValidator]),
      description: new FormControl(null),
      privacy: new FormControl(this.displayPrivacy ? 'TeamEdit' : 'OnlyMe'),
    });
  }

  public noWhitespaceValidator(control: FormControl) {
    return (control.value || '').trim().length ? null : { whitespace: true };
  }

  createPlaylist() {
    this.loading = true;
    const playlistForm = this.playlistForm.value;
    const user = this.authenticationService.getCurrentUser();
    if (user?.hasOwnProperty('nickname')) {
      playlistForm.nickname = user.nickname;
    }
    if (user?.hasOwnProperty('groups') && playlistForm.privacy !== 'OnlyMe') {
      playlistForm.groups = user.groups;
      playlistForm.allowTrackAdditions = playlistForm.privacy === 'TeamEdit' ? 'team' : 'only-me';
    }
    this.playlistsStereoSpacesService.post(playlistForm).subscribe({
      next: (res) => {
        this.success.emit(res);
        this.playlistForm.reset({ privacy: this.displayPrivacy ? 'TeamView' : 'OnlyMe' });
        this.playlistsCount.next(this.playlistsCount.getValue() + 1);
        this.trackingService.track(
          'Create Playlist',
          { data: res?.data },
          {
            event_action: 'Playlist created',
            event_type: 'Playlist',
            element_type: 'Playlist',
            element_name: res?.data?.name,
            element_id: res?.data?._id,
          },
        );
        this.loading = false;
        this.hideModal();
      },
      error: (err) => {
        this.loading = false;
        if (err.error?.hasOwnProperty('message')) {
          this.error.emit(err.error.message);
        } else {
          this.error.emit(err);
        }
        this.playlistForm.reset({ privacy: this.displayPrivacy ? 'TeamView' : 'OnlyMe' });
      },
    });
  }

  public showModal() {
    this.modalRef = this.modalService.show(this.modalTemplate, {
      class: 'modal-md',
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  public hideModal() {
    this.modalRef.hide();
  }

  public onKeydown(event) {
    event.stopPropagation();
  }
}
